import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        customColor: "#f9a533", // Your custom color
      },
      dark: {
        customColor: "#f9a533", // Custom color for dark theme (optional)
      },
    },
  },
});
