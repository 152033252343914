export default {
  methods: {
    formatDate(date) {
      let formatter = new Intl.DateTimeFormat("ua", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });
      return formatter.format(new Date(date));
    },
    formatDateLong(date) {
      let formatter = new Intl.DateTimeFormat("ua", {
        hour: "numeric",
        minute: "numeric",
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });
      return formatter.format(new Date(date));
    },
  },
};
