import { mapGetters } from "vuex";
import { Permissions } from "@/servises/index";
export default {
  computed: {
    ...mapGetters("auth", ["currentUser"]),
  },
  methods: {
    PermissionCheckerEvent(permission) {
      return (
        this.currentUser &&
        Permissions[this.currentUser.role.value] &&
        Permissions[this.currentUser.role.value][permission]
      );
    },
  },
};
