import { getCurrentUser } from "@/api/auth";

export default {
  namespaced: true,
  state: {
    currentUser: null,
  },
  getters: {
    currentUser: (state) => state.currentUser,
  },
  mutations: {
    set(state, { type, items }) {
      state[type] = items;
    },
  },
  actions: {
    async getCurrentUser({ commit }, params) {
      await getCurrentUser(params)
        .then((response) => {
          commit("set", { type: "currentUser", items: response.data });
        })
        .catch(() => {
          localStorage.removeItem("userToken");
          window.location.href = "/login";
        });
    },
  },
};
