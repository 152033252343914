import axios from "axios";
import config from "./config";
import Vue from "vue";

const instanse = axios.create(config());

instanse.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("userToken");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    config.headers["Content-Type"] = "application/json";

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

instanse.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    let typeError = typeof error.response.data.errors;

    if (typeError === "string") {
      Vue.notify({
        group: "foo",
        title: error.response.data.errors,
        type: "error",
      });
    } else {
      for (let key in error.response.data.errors) {
        Vue.notify({
          group: "foo",
          title: `${error.response.data.errors[key]}`,
          type: "error",
        });
      }
    }
    if (error.response.status === 401) {
      localStorage.removeItem("userToken");
      window.location.href = "/login";
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);

export default instanse;
