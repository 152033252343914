import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

Vue.config.productionTip = false;

import DateFormater from "./mixins/DateFormater";
Vue.mixin(DateFormater);

import Notifications from "vue-notification";
Vue.use(Notifications);

import PermissionChecker from "./mixins/PermissionChecker";
Vue.mixin(PermissionChecker);

import VueConfirmDialog from "vue-confirm-dialog";

Vue.use(VueConfirmDialog);
Vue.component("vue-confirm-dialog", VueConfirmDialog.default);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
