import Vue from "vue";
import VueRouter from "vue-router";
import { Permissions } from "@/servises/index";
import store from "@/store";
import { getCurrentUser } from "@/api/auth";
Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/LoginView.vue"),
  },
  {
    path: "/calculator",
    name: "calculator_page",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../pages/calculator/CalculatorPage"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/GlobalView"),
    meta: { requiresAuth: true },
    children: [
      {
        path: "/",
        name: "dashboard_page",
        component: () =>
          import(/* webpackChunkName: "about" */ "../pages/manuals/Manuals"),
      },
      {
        path: "/manuals/:id",
        name: "manual_page",
        component: () =>
          import(/* webpackChunkName: "about" */ "../pages/manuals/ManualPage"),
      },
      {
        path: "/users",
        name: "users_page",
        component: () =>
          import(/* webpackChunkName: "about" */ "../pages/users/UsersList"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/documents",
        name: "documents_page",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../pages/documents/Documents"
          ),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

let GoToNextPage = (profile, page, next) => {
  if (Permissions[profile.role.value][page]) {
    next();
  } else {
    next("/");
  }
};

router.beforeEach((to, from, next) => {
  const isAuthenticated = !!localStorage.getItem("userToken");
  let user = store.state.currentUser;
  if (to.path == "/login" && isAuthenticated) {
    next("/");
    return;
  }

  if (to.matched.some((route) => route.meta.requiresAuth)) {
    if (isAuthenticated) {
      if (to.meta.requiresAuth) {
        if (!user) {
          getCurrentUser().then((response) => {
            user = response.data;
            GoToNextPage(response.data, to.name, next, to);
          });
        } else {
          GoToNextPage(store.state.auth.currentUser, to.name, next, to);
        }
      } else {
        next();
      }
    } else {
      next("/login");
    }
  } else {
    next();
  }
});

export default router;
