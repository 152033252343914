<template>
  <v-app>
    <router-view />
    <notifications group="foo" />
    <GlobalLoader />
  </v-app>
</template>

<script>
import { isBoolean } from "@/servises/index";
import GlobalLoader from "@/components/GlobalLoader.vue";

export default {
  name: "App",
  components: {
    GlobalLoader,
  },

  data: () => ({
    //
  }),
  created() {
    window.getApp = this;
    let themeMode = JSON.parse(localStorage.getItem("darkMode"));
    if (isBoolean(themeMode)) {
      this.$vuetify.theme.dark = themeMode;
      this.darkMode = themeMode;
    }
  },
};
</script>

<style>
@import "scss/main.scss";
@import "scss/base.css";
</style>
